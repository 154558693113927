<template>
  <!-- 支付结果 -->
  <div id="app">
    <div class="cart-con confirm-con pay-con">
      <div class="cart-ban">
        <img src="@/assets/img/confirm-ban.png" />
      </div>
      <div class="cson-container">
        <div class="current">
          <ul>
            <li>
              <img src="@/assets/img/confirm-cur1.png" />
              <p class="cur">确认客户订单</p>
              <span></span>
            </li>
            <li>
              <img src="@/assets/img/confirm-cur22.png" />
              <p class="cur">订单支付</p>
              <span></span>
            </li>
            <li>
              <img src="@/assets/img/confirm-cur33.png" />
              <p class="cur">支付结果</p>
            </li>
          </ul>
        </div>
        <div class="pay-result">
          <div class="result">
            <img src="@/assets/center/bingo-icon.png" />
            <h2>支付成功</h2>
          </div>
          <div class="code-con">
            <img class="code" src="@/assets/img/kefu-qrcode.jpeg" />
            <p>
              一个月以内，若购买其他课程，可抵扣$20<br />
              请扫码添加客服咨询详情！
            </p>
          </div>
<!--          <div class="result-btn">-->
<!--            <button @click="startLearn">加入答疑班级群</button>-->
<!--          </div>-->
        </div>
      </div>
    </div>

    <div v-if="dialogFormVisible" class="submit-con">
      <div class="box zhuan-box pay-box" style="height: 280px; width: 300px">
        <img
          v-if="qr"
          :src="qr.imageUrl"
          class="code"
          style="height: 150px; width: 150px"
        />

        <p style="line-height: 26px">
          您所购买的课程，包含答疑服务
          <br />
          请扫码添加客服开通！
        </p>

        <img
          class="close"
          src="@/assets/img/close-icon.png"
          @click="dialogFormVisible = false"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { getCourseBanner } from "../../service";

export default {
  data() {
    return {
      dialogFormVisible: false,
      qr: null,
    };
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    startLearn() {
      // this.$router.push("/center/mylesson");
      this.dialogFormVisible = true;
      // 拿二维码图片
      getCourseBanner(5)
        .then((res) => {
          console.log(res);
          if (!res.result.code) {
            console.log(res);
            this.qr = res.result[0];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<!-- Event snippet for 购买课程 conversion page -->
<script>
gtag('event', 'conversion', {
  'send_to': 'AW-316705807/iMdfCJT_xO4CEI-YgpcB',
  'transaction_id': ''
});
</script>

<style>
</style>
